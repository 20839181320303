<template>
    <div class="view pa24">
        <commonTable :tableData="tableData" :paginationVisible="false">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="title" width="180" label="门禁设置" show-overflow-tooltip />
                <el-table-column prop="note" label="说明">
                    <template slot-scope="scope">
                        <span>{{ scope.row.note }}</span>
                        <el-input v-if="scope.row.id === 1" style="width:300px;margin-left:30px" placeholder="输入定位范围" v-model="km">
                            <template slot="append">千米（KM）</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="是否启用" width="130">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.switch" :active-value="1" :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <div class="text-align">
            <el-button type="primary">提 交</el-button>
        </div>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
// import { selectAllMemberLevel, setMemberLevel } from "@/api/member";
export default {
    name: "infoTable",
    components: {
        commonTable,
    },
    data() {
        return {
            km: "",
            tableData: [
                { id: 1, title: "开门定位", note: "开启后，业主通过通过定位显示附近能开的门，默认不开定位", switch: 0 },
                { id: 2, title: "微信开门", note: "开启的话，业主注册默认就有微信开门权限", switch: 0 },
                { id: 3, title: "授权验证开门", note: "默认微信开门采用的业主注册的楼栋单元匹配，进行开门。开启授权验证开门后，需要先在后台设置业主授权开门", switch: 0 },
                { id: 4, title: "微信开门关联物业费", note: "此设置与微信开门硬件有关，开启此设置后，会关联业主的物业费", switch: 0 },
                { id: 5, title: "取消关注删除人脸授权", note: "开启后，粉丝取消关注后删除对应粉丝的人脸授权，默认不删除", switch: 0 },
                { id: 6, title: "门禁到期推送", note: "开启后，门禁到期后对用户进行推送提醒", switch: 0 }
            ],
            userInfo: this.$store.state.loginRoot.userInfo,
        };
    },
    filters: {

    },
    created() {

    },
    methods: {

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>